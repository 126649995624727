import { compose } from 'recompose';
import { connect } from 'react-redux';
import Select from 'react-select';
import React, { Component } from 'react';
import { upperFirst } from 'lodash';

import { selectDistrict } from 'modules/actions/mapData';

import 'react-select/dist/react-select.css';

class DistrictSelect extends Component {
  getItems = () => {
    const { mapData } = this.props;
    // Create an array of our districts
    const items = mapData.geoJson.features
      .map((feature) => ({
        label: feature.properties.lea_name,
        value: feature.properties.unsdlea,
      }))
      .sort((a, b) => {
        if (a.label > b.label) return -1;
        else if (a.label < b.label) return 1;
        else return 0;
      });
    items.push({ label: upperFirst(mapData.stateKey), value: '' });
    return items.reverse();
  };

  updateSelection = (e) => {
    // Need to set value to null to clear
    let value = null;
    if (e && e.value) {
      value = e.value;
    }
    this.props.selectDistrict(value);
  };

  render() {
    const { mapData } = this.props;
    let selectedDistrict = '';
    if (mapData.highlightedDistrict) {
      selectedDistrict = mapData.highlightedDistrict;
    } else if (mapData.selectedDistrict) {
      selectedDistrict = mapData.selectedDistrict;
    }

    return (
      <Select
        className="dropdown"
        options={this.getItems()}
        onChange={this.updateSelection}
        value={selectedDistrict}
      />
    );
  }
}

export default compose(
  connect(
    (state) => ({
      mapData: state.mapData,
    }),
    {
      selectDistrict,
    },
  ),
)(DistrictSelect);
