import { findIndex } from 'lodash';

import { CSV } from 'lib/csv';

const summaryDataKeys = [
  'funding',
  'avg_funding_stu',
  'pct_funding_from_state',
  'pct_funding_from_local',
];
const equityMeasureKeys = [
  'avg_funding_stu_inpov',
  'avg_funding_stu_notinpov',
  'avg_funding_col_stu',
  'avg_funding_wh_stu',
  'avg_funding_rur_stu',
  'avg_funding_urb_stu',
];

const generateCSV = (params) => (dispatch, getState) => {
  const { controls, mapData } = getState();
  const { state, districts } = mapData;
  const {
    controlValues,
    currentFundingPlan,
    currentFundingPlanEquityMeasures,
    currentFundingPlanSummaryData,
    districtData,
    proposedFundingPlan,
    proposedFundingPlanEquityMeasures,
    proposedFundingPlanSummaryData,
  } = params;
  let data = [];
  let columns = ['key'];
  if (currentFundingPlan) {
    columns.push('current');
    if (currentFundingPlanSummaryData) {
      data = data.concat(
        summaryDataKeys.map((key) => ({
          key,
          current: state[key],
        })),
      );
    }
    if (currentFundingPlanEquityMeasures) {
      data = data.concat(
        equityMeasureKeys.map((key) => ({
          key,
          current: state[key],
        })),
      );
    }
  }
  if (proposedFundingPlan) {
    columns.push('proposed');
    if (proposedFundingPlanSummaryData) {
      summaryDataKeys.forEach((key) => {
        const rowIndex = findIndex(data, { key });
        if (rowIndex !== -1) {
          data[rowIndex].proposed = state['proposed_' + key] || 'null';
        } else {
          data.push({
            key,
            proposed: state['proposed_' + key] || 'null',
          });
        }
      });
    }
    if (proposedFundingPlanEquityMeasures) {
      equityMeasureKeys.forEach((key) => {
        const rowIndex = findIndex(data, { key });
        if (rowIndex !== -1) {
          data[rowIndex].proposed = state['proposed_' + key] || 'null';
        } else {
          data.push({
            key,
            proposed: state['proposed_' + key] || 'null',
          });
        }
      });
    }
  }
  if (controlValues) {
    data = data.concat(
      Object.keys(controls).map((key) => {
        const proposed = getProposedValue(controls[key].proposedValue);
        return {
          key: controls[key].label,
          current: controls[key].defaultValue || controls[key].toggle,
          proposed,
        };
      }),
    );
  }
  if (districtData) {
    columns = columns.concat([
      'unsdlea',
      'statename',
      'lea_name',
      'gslo',
      'gshi',
      'member',
      'per_students_of_color',
      'percent_pov',
      'percent_ell',
      'percent_speced',
      'geography',
      'avg_funding_stu',
      'pct_funding_from_local',
      'pct_funding_from_state',
      'proposed_avg_funding_stu',
      'proposed_pct_funding_from_local',
      'proposed_pct_funding_from_state',
    ]);
    data = data.concat(
      districts.map((d) => {
        d.key = 'district';
        return d;
      }),
    );
  }
  CSV({
    data,
    fileName: 'Education_Funding_Formula',
    columns,
  });
};

const getProposedValue = (value) => {
  switch (true) {
    case value === undefined: {
      return '';
    }
    case value === null: {
      return '';
    }
    default: {
      return value;
    }
  }
};

export default generateCSV;
