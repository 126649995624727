import React, { Component } from 'react';
import { find, round, startCase } from 'lodash';

import Control from './Control';

import './ConnectedControl.scss';

export default class ConnectedControl extends Component {
  state = {};

  // TODO: We currently only allow one to be locked at a time which
  // works for 3 connected controls but falls apart with more
  handleOnLock = (e) => {
    const { name, checked } = e.target;
    const { state } = this;
    const match = name.match(/^lock_(\w*)/);
    const stateKey = match[1];
    // We only allow one checkbox to be checked at a time
    if (checked === true) {
      Object.keys(state).forEach((k) => {
        state[k] = false;
      });
    }
    state[stateKey] = checked;
    this.setState(state);
  };

  handleOnChange = (e) => {
    const { controls, setControlValue } = this.props;
    const { state } = this;
    const key = e.target.name;
    const control = find(controls, { key });
    let value = parseFloat(e.target.value);

    // Add all locked control values together
    const lockSum = controls.reduce((acc, control) => {
      if (state[control.key]) {
        const val = control.proposedValue || control.defaultValue;
        return acc + val;
      }
      return acc;
    }, 0);

    // Check if new value plus locked values is more than 1.00
    // If it is, increase this only to the max value possible
    if (lockSum + value > 1) {
      value = round(1 - lockSum, 2);
    }

    const origVal = control.proposedValue || control.defaultValue;
    // difference of new value vs existing
    let dif = round(value - origVal, 2);

    const needsUpdating = controls.reduce((acc, control) => {
      if (!state[control.key] && control.key !== key) {
        acc.push(control);
        return acc;
      }
      return acc;
    }, []);

    // This is the change that we should be able to apply to each control
    let proportionalChange = (dif / needsUpdating.length) * -1;
    // We are trying to round down the proportionalChange to ensure we
    // don't offer a change to a single control that is greater than 1
    if (proportionalChange > 0) {
      proportionalChange = round(Math.floor(proportionalChange * 100) / 100, 2);
    } else {
      proportionalChange = round(Math.ceil(proportionalChange * 100) / 100, 2);
    }
    let runningTotal = value + lockSum;
    // Iterate through controls to update each with their new value
    // No need to update ones that are locked
    needsUpdating.forEach((control, i) => {
      let change;

      const val = control.proposedValue || control.defaultValue;

      // Length starts at 1 and our index starts at 0
      if (i < needsUpdating.length - 1) {
        change = round(proportionalChange + val, 2);
        // If our change pushes this over 1, set it back to a sum equal to 1
        if (change + runningTotal > 1) {
          change = round(1 - runningTotal, 2);
          runningTotal = 1;
        } else {
          runningTotal = round(runningTotal + Math.abs(change), 2);
        }
      } else {
        change = round(1 - runningTotal, 2);
      }
      setControlValue(
        {
          key: control.key,
          value: change,
          rangeLower: control.rangeLower,
          rangeUpper: control.rangeUpper,
        },
        false, // We don't want to update location until the last one
      );
    });

    // Finally set the value of the control that changed
    setControlValue(
      {
        key,
        value,
        rangeLower: control.rangeLower,
        rangeUpper: control.rangeUpper,
      },
      true, // Update location now
    );
  };

  render() {
    const { controls, groupKey } = this.props;
    return (
      <React.Fragment>
        <h2>{startCase(groupKey)}</h2>
        <div className="connected-control bg-light px-2 py-3 mb-4 border">
          {controls.map((control) => (
            <Control
              {...control}
              key={control.key}
              field={control.key}
              onChange={this.handleOnChange}
              onLock={this.handleOnLock}
              locked={!!this.state[control.key]}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}
