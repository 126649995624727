// @flow
import _ from 'lodash';
import { saveAs } from 'file-saver';

// Save a dataset as a file format.
export function CSV({ data, fileName, query, columns }: CSVArgs) {
  if (!fileName) fileName = 'export';
  saveCSV(createCSVData(data, columns), formatCSVFilename(fileName, query));
}

//
// CSV Helpers
//

function saveCSV(csv, fileName) {
  return openFile(csv, fileName, 'text/csv;charset=utf-8;');
}

// Reference to the CSV standard: https://tools.ietf.org/html/rfc4180
function createCSVData(data, inputColumns) {
  // Is this a collection?
  // Convert an array of objects into an array of arrays
  const columns = inputColumns || _.keys(data[0]);
  const valueData = data.map((d) => columns.map((c) => d[c]));
  const csvData = [columns, ...valueData];
  // Escape columns inside each row, join them with commas, then join rows with newlines
  return csvData.map((row) => row.map(escape).toString()).join('\r\n');
}

function formatCSVFilename(s, query) {
  if (query != null) {
    s +=
      '_' +
      query
        .replace(/\{/g, '(')
        .replace(/\}/g, ')')
        .replace(/:/g, '=')
        .replace(/"/g, '');
  }
  const now = new Date();
  return (
    s +
    '_' +
    [now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()].join('-') +
    '.csv'
  );
}

//
// General helpers
//

async function openFile(data, fileName, mime) {
  const blob = new Blob([data], { type: mime });
  saveAs(blob, fileName);
}

function escape(val) {
  let value = val;
  if (value instanceof Date) {
    value = csvDateFormat(value);
  }
  if (value === undefined) {
    return '';
  }
  value = String(value).replace(/"/g, '""');
  return `"${value}"`;
}

function csvDateFormat(val) {
  // Intentionally not UTC, use user's local
  return val.toLocaleString();
}
