import { some, throttle } from 'lodash';
import React, { Component } from 'react';
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl';

import { BLUE_COLOR_RAMP } from 'lib/map';

const Mapbox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidXJiYW5pbnN0aXR1dGUiLCJhIjoiTEJUbmNDcyJ9.mbuZTy4hI_PWXw3C3UFbDQ',
});

// media query js
const mq = window.matchMedia('(min-width: 768px)');

// media query change
let mapHeight = '100vh';
if (mq.matches) {
  mapHeight = '50vh';
} else {
  mapHeight = '65vh';
}

export default class Map extends Component {
  // We are handling hovering at the component level because redux is too slow
  // to quickly render the hovered layer
  state = {
    hoveredDistrict: null,
  };

  // Not completely happy with how slow this is but don't see a better way yet
  handleMouseEnter = (e) => {
    if (e.features.length > 0) {
      const unsdlea = e.features[0].properties.unsdlea;
      this.throttleHighlightDistrict(unsdlea);
      this.setState({ hoveredDistrict: unsdlea });
    }
  };

  handleMouseClick = (e) => {
    const { selectedDistrict } = this.props.mapData;
    if (e.features.length > 0) {
      const unsdlea = e.features[0].properties.unsdlea;
      this.props.selectDistrict(unsdlea === selectedDistrict ? null : unsdlea);
    }
  };

  handleMouseExit = (e) => {
    this.throttleHighlightDistrict(null);
    this.setState({ hoveredDistrict: null });
  };

  // If we don't throttle we get major performance issues
  throttleHighlightDistrict = throttle(this.props.highlightDistrict, 300);

  render() {
    const { mapData } = this.props;
    if (!mapData) return null;

    // Decides whether to use the proposed key or non for dollars per student
    let displayProposed = false;
    if (
      some(mapData.geoJson.features, (f) => {
        return 'proposed_avg_funding_stu' in f.properties;
      })
    ) {
      displayProposed = true;
    }

    const highlighted = mapData.geoJson.features.filter(
      (district) => district.properties.unsdlea === this.state.hoveredDistrict,
    );
    const selected = mapData.geoJson.features.filter(
      (district) => district.properties.unsdlea === mapData.selectedDistrict,
    );

    return (
      <Mapbox
        style="mapbox://styles/mapbox/light-v9" // eslint-disable-line
        containerStyle={{
          height: mapHeight,
          width: '100%',
        }}
        center={mapData.center}
        zoom={mapData.zoom}
      >
        <GeoJSONLayer
          id="state"
          data={mapData.geoJson}
          lineLayout={{
            visibility: 'visible',
            'line-join': 'round',
            'line-cap': 'round',
          }}
          linePaint={{
            'line-width': 1,
            'line-color': '#999999',
          }}
          sourceOptions={{
            type: 'geojson',
          }}
          fillPaint={{
            'fill-color': {
              property: displayProposed
                ? 'proposed_avg_funding_stu'
                : 'avg_funding_stu',
              stops: [
                [mapData.stops[0], BLUE_COLOR_RAMP[0]],
                [mapData.stops[1], BLUE_COLOR_RAMP[1]],
                [mapData.stops[2], BLUE_COLOR_RAMP[2]],
                [mapData.stops[3], BLUE_COLOR_RAMP[3]],
                [mapData.stops[4], BLUE_COLOR_RAMP[4]],
              ],
            },
            'fill-opacity': 0.5,
          }}
          fillOnMouseMove={this.handleMouseEnter}
          fillOnMouseEnter={this.handleMouseEnter}
          fillOnMouseLeave={this.handleMouseExit}
        />
        {highlighted.length > 0 && (
          <GeoJSONLayer
            sourceOptions={{
              type: 'geojson',
            }}
            data={highlighted[0]}
            fillPaint={{
              'fill-color': '#666',
              'fill-opacity': 0.5,
            }}
            fillOnClick={this.handleMouseClick}
          />
        )}
        {selected.length > 0 && (
          <GeoJSONLayer
            sourceOptions={{
              type: 'geojson',
            }}
            data={selected[0]}
            fillPaint={{
              'fill-color': '#fbb755',
              'fill-opacity': 0.5,
            }}
          />
        )}
      </Mapbox>
    );
  }
}
