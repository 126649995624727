import {
  SET_CONTROLS,
  SET_CONTROL_VALUE,
  RESET_CONTROL_VALUES,
} from 'modules/actions/controls';
import { GET_SIMULATOR } from 'modules/actions/simulators';

import { clamp } from 'lib/number';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTROLS: {
      return payload.controls.reduce((all, control) => {
        // defaultValue is now coming as a string so we parse it into a float or integer
        if (typeof control.defaultValue === 'string') {
          control.defaultValue = parseFloat(control.defaultValue);
        }
        return {
          ...all,
          [control.key]: { ...control, proposedValue: null },
        };
      }, {});
    }
    case SET_CONTROL_VALUE: {
      const { toggle, rangeLower, rangeUpper } = payload;
      const proposedValue =
        toggle !== null && toggle !== undefined
          ? payload.value
          : clamp(payload.value, rangeLower, rangeUpper);

      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          proposedValue,
        },
      };
    }
    case RESET_CONTROL_VALUES: {
      return Object.values(state).reduce(
        (all, control) => ({
          ...all,
          [control.key]: { ...control, proposedValue: null },
        }),
        {},
      );
    }
    case GET_SIMULATOR: {
      return {};
    }
    default:
      return state;
  }
};
