import React, { Component } from 'react';

import TooltipIcon from './TooltipIcon';

import { differenceObject, displayValue } from 'lib/number';

import './SummaryData.scss';

export default class SummaryData extends Component {
  render() {
    const {
      currentValue,
      dataKey,
      proposedValue,
      subTitle,
      title,
      toolTip,
    } = this.props;
    const difference = differenceObject(currentValue, proposedValue);
    return (
      <div className="col-12 col-md summary-datum mb-3 mb-md-0">
        <div className="row">
          <div className="col-12">
            <h3 className={subTitle ? 'mb-0' : ''}>
              {title}
              <TooltipIcon id={dataKey} text={toolTip} />
            </h3>
            {subTitle && <small>{subTitle}</small>}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <small className="text-uppercase">Current</small>
            <h4 className="font-weight-normal">
              {displayValue(currentValue, dataKey)}
            </h4>
          </div>
        </div>
        {!!proposedValue ? (
          <div className="row">
            <div className="col-12">
              <small className="text-uppercase">
                Proposed
                <span
                  className={
                    'ml-1 ' +
                    (difference.sign === '+' ? 'increase' : 'decrease')
                  }
                >
                  {difference.string}
                </span>
              </small>
              <h4 className="font-weight-normal">
                {displayValue(proposedValue, dataKey)}
              </h4>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
