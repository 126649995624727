import {
  HIGHLIGHT_DISTRICT,
  SELECT_DISTRICT,
  SET_MAP,
} from 'modules/actions/mapData';
import {
  RESET_CONTROL_VALUES,
  UPDATE_PROPOSED,
} from 'modules/actions/controls';
import { quantileIntervals } from 'lib/map';
import { cloneDeep, find, forEach, merge } from 'lodash';

export default (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MAP: {
      const mapData = cloneDeep(payload.mapData);
      // Create array to hold the value we want to use for our choropleth
      const values = [];
      // Adds properties to all district geojson featuers
      mapData.geoJson.features.forEach((feature) => {
        if (!feature.properties) return;
        // geoJson has the unsdlea property captialized. After this it is not.
        const unsdlea = parseInt(feature.properties.unsdlea, 10);

        const newProps = find(mapData.districts, {
          unsdlea,
        });
        if (newProps) {
          // Update values with newProps value we want
          values.push(newProps.avg_funding_stu);
          // We don't need any of the old properties
          feature.properties = newProps;
        } else {
          // console.log('Missing data for ' + feature.properties.unsdlea);
        }
      });
      // Create the stops we need for our legend and the choropleth
      mapData.stops = quantileIntervals(values);
      return mapData;
    }
    case SELECT_DISTRICT: {
      return Object.assign({}, state, {
        selectedDistrict: payload ? parseInt(payload, 10) : null,
      });
    }
    case HIGHLIGHT_DISTRICT: {
      return Object.assign({}, state, {
        highlightedDistrict: payload ? parseInt(payload, 10) : null,
      });
    }
    case UPDATE_PROPOSED: {
      const newState = cloneDeep(state);
      const values = [];
      // Update state with proposed keys or overwrite existing
      newState.state = merge(newState.state, payload.state);
      // Iterate through each to add district values
      newState.geoJson.features.forEach((feature) => {
        if (!feature.properties) return;

        const newProps = payload.district[feature.properties.unsdlea];
        if (newProps) {
          // Update values with newProps value we want
          values.push(newProps.proposed_avg_funding_stu);
          // We don't need any of the old properties
          feature.properties = merge(feature.properties, newProps);
        } else {
          // console.log('Missing data for ' + feature.properties.unsdlea);
        }
      });
      // Create the stops we need for our legend and the choropleth
      newState.stops = quantileIntervals(values);
      return newState;
    }
    case RESET_CONTROL_VALUES: {
      const mapData = cloneDeep(state);
      // Create array to hold the value we want to use for our choropleth
      const values = [];
      // Adds properties to all district geojson featuers
      mapData.geoJson.features.forEach((feature) => {
        if (!feature.properties) return;
        // geoJson has the unsdlea property captialized. After this it is not.
        const unsdlea = parseInt(feature.properties.unsdlea, 10);

        const newProps = find(mapData.districts, {
          unsdlea,
        });
        if (newProps) {
          removeProposedKeys(newProps);
          // Update values with newProps value we want
          values.push(newProps.avg_funding_stu);
          // We don't need any of the old properties
          feature.properties = newProps;
        } else {
          // console.log('Missing data for ' + feature.properties.unsdlea);
        }
      });
      // Create the stops we need for our legend and the choropleth
      mapData.stops = quantileIntervals(values);

      removeProposedKeys(mapData.state);

      return mapData;
    }
    default:
      return state;
  }
};

const removeProposedKeys = (obj) => {
  forEach(obj, (v, k) => {
    if (k.includes('proposed')) {
      delete obj[k];
    }
  });
};
