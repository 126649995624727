import React, { Component } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianAxis,
  Tooltip,
} from 'recharts';
import { camelCase, startCase } from 'lodash';
import { differenceObject } from 'lib/number';

import TooltipIcon from './TooltipIcon';

import './EquityMeasure.scss';

export default class EquityMeasure extends Component {
  render() {
    const { groups, maxValue, text, title, toolTip } = this.props;
    const id = camelCase(title);
    return (
      <div className="col-12 mb-5 mb-md-4">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0 order-2 order-md-1">
            <div className="graph-group">
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  data={groups}
                  layout="vertical"
                  barCategoryGap="15%"
                  bargap={1}
                  margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
                >
                  <CartesianAxis />
                  <XAxis
                    domain={[0, maxValue]}
                    tickFormatter={(value) =>
                      '$' + new Intl.NumberFormat('en').format(value)
                    }
                    type="number"
                  />
                  <YAxis type="category" dataKey="title" />
                  <Tooltip cursor={{ fill: '#f5f5f5' }} content={content} />
                  <Bar dataKey="current" fill="#bebdbe" />
                  <Bar dataKey="proposed">
                    {groups.map((entry) => {
                      if (!entry.proposed) {
                        return null;
                      }
                      return (
                        <Cell
                          key={entry.title}
                          fill={generateFill(entry.current, entry.proposed)}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 order-1 order-md-2">
            <h3>
              {title}
              <TooltipIcon id={id} text={toolTip} />
            </h3>
            <p>{text}</p>
          </div>
        </div>
      </div>
    );
  }
}

const content = (props) => (
  <div
    className="popover show"
    role="tooltip"
    style={{ position: 'relative', color: '#231f20' }}
  >
    <div className="popover-body">
      <p className="mb-2">
        <strong>{props.label}</strong>
      </p>
      <ul className="list-unstyled m-0">
        {props.payload.map((item) => {
          let percentString = '';
          let color = '#bebdbe';
          if (item.name === 'proposed') {
            const { current, proposed } = item.payload;
            if (!proposed) {
              return null;
            }
            const difObj = differenceObject(current, proposed);
            if (difObj.sign === '-') {
              color = '#c85818';
            }
            if (difObj.sign === '+') {
              color = '#4097d1';
            }
            percentString = ` ${difObj.sign}${difObj.percent}%`;
          }
          return (
            <li key={item.name}>
              {startCase(item.name)}: ${new Intl.NumberFormat('en').format(
                item.value,
              )}
              <span style={{ color }}>{percentString}</span>
            </li>
          );
        })}
      </ul>
    </div>
  </div>
);

function generateFill(current, proposed) {
  switch (true) {
    case current < proposed:
      return '#4097d1';
    case current > proposed:
      return '#c85818';
    default:
      return '#bebdbe';
  }
}
