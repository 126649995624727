import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const TooltipIcon = ({ id, text }) => {
  if (!text || (text && text.length === 0)) {
    return null;
  }
  return (
    <React.Fragment>
      &nbsp;
      <span id={id} className="tooltip-icon">
        ⓘ
      </span>
      <UncontrolledTooltip target={id} trigger="click">
        {text}
      </UncontrolledTooltip>
    </React.Fragment>
  );
};
export default TooltipIcon;
