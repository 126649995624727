import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import './Loader.scss';

export const Loader = (props) => (
  <div className={`col-md-8 loader-overlay ${props.loading ? 'loading' : ''}`}>
    <div className="loader-spinner">Loading...</div>
  </div>
);

export default compose(connect((state) => ({ loading: state.loading })))(
  Loader,
);
