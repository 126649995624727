import { combineReducers } from 'redux';

import controls from './controls';
import loading from './loading';
import mapData from './mapData';
import simulators from './simulators';

export default combineReducers({
  controls,
  loading,
  mapData,
  simulators,
});
