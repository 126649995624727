import React from 'react';
import { isNull, isString, round } from 'lodash';

import TooltipIcon from './TooltipIcon';

const content = {
  per_students_of_color: {
    label: 'Students of color',
    tooltip:
      'Nonwhite students enrolled in district schools, NCES 2015–16 Common Core of Data.',
  },
  percent_pov: {
    label: 'Children in poverty',
    tooltip:
      'Children ages 5 to 17 living in the school district, 2015 Census Bureau estimate.',
  },
  percent_ell: {
    label: 'English language learners',
    tooltip:
      'Students served in English language learner programs, NCES 2015–16 Common Core of Data.',
  },
  percent_speced: {
    label: 'Students in special education',
    tooltip:
      'Students ages 3 to 21 with an individualized education plan, National Center for Education Statistics (NCES) 2015–16 Common Core of Data.',
  },
};

const SummaryBarItem = ({ value, labelKey }) => (
  <div className="col-12 col-md-6">
    <small>
      {content[labelKey].label}
      <TooltipIcon id={labelKey} text={content[labelKey].tooltip} />
    </small>
    <h4 className="font-weight-normal">{generateDisplayValue(value)}</h4>
  </div>
);
export default SummaryBarItem;

const generateDisplayValue = (value) => {
  if (isNull(value)) {
    return 'No data';
  }
  if (isString(value)) {
    return value;
  }
  // End up with a number with commas
  if (Number.isInteger(value)) {
    return value.toLocaleString();
  }
  // It should be a precision value at this point
  // Give us either XX% or 0.X%
  if (round(value * 100) === 0) {
    return round(value * 100, 1) + '%';
  }
  return round(value * 100) + '%';
};
