import { GET_SIMULATORS, SET_SIMULATORS } from 'modules/actions/simulators';

export default (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SIMULATORS: {
      return []; // Empty out those simulators
    }
    case SET_SIMULATORS: {
      return payload;
    }
    default:
      return state;
  }
};
