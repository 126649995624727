import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import generateCSV from 'modules/actions/generateCSV';

import Modal from 'components/Modal';

class ExportModal extends Component {
  state = {
    controlValues: true,
    currentFundingPlan: true,
    currentFundingPlanEquityMeasures: true,
    currentFundingPlanSummaryData: true,
    districtData: true,
    proposedFundingPlan: true,
    proposedFundingPlanEquityMeasures: true,
    proposedFundingPlanSummaryData: true,
  };

  handleChange = (prefix, field) => (ev) => {
    const { checked } = ev.target;

    this.setState({ [`${prefix}${field}`]: checked }, () => {
      if (
        !checked &&
        !this.state[`${prefix}SummaryData`] &&
        !this.state[`${prefix}EquityMeasures`]
      ) {
        this.setState({ [prefix]: false });
      } else if (checked) {
        this.setState({ [prefix]: true });
      }
    });
  };

  handleParentChange = (prefix) => (ev) => {
    const { checked } = ev.target;

    this.setState({
      [prefix]: checked,
      [`${prefix}SummaryData`]: checked,
      [`${prefix}EquityMeasures`]: checked,
    });
  };

  handleClick = () => {
    const { generateCSV, onRequestClose } = this.props;
    generateCSV(this.state);
    onRequestClose();
  };

  render() {
    const { isOpen, mapData, onRequestClose } = this.props;
    const {
      controlValues,
      currentFundingPlan,
      currentFundingPlanEquityMeasures,
      currentFundingPlanSummaryData,
      districtData,
      proposedFundingPlan,
      proposedFundingPlanEquityMeasures,
      proposedFundingPlanSummaryData,
    } = this.state;

    const buttonDisabled =
      Object.values(this.state).filter((v) => v === true).length === 0;

    const disableProposed =
      mapData &&
      Object.keys(mapData.state).filter((key) => {
        if (key.includes('proposed_') && mapData.state[key] !== null) {
          return true;
        }
        return false;
      }).length === 0;

    return (
      <Modal
        title="Export"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        actionLabel="Download Dataset"
        actionDisabled={buttonDisabled}
        onActionClick={this.handleClick}
      >
        <h1 className="h2">Export dataset as CSV</h1>

        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={currentFundingPlan}
              onChange={this.handleParentChange('currentFundingPlan')}
            />
            Current Funding Plan
          </label>

          <div className="form-check">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                checked={currentFundingPlanSummaryData}
                onChange={this.handleChange(
                  'currentFundingPlan',
                  'SummaryData',
                )}
              />
              Summary Data
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                checked={currentFundingPlanEquityMeasures}
                onChange={this.handleChange(
                  'currentFundingPlan',
                  'EquityMeasures',
                )}
              />
              Equity Measures
            </label>
          </div>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input
              checked={!disableProposed && proposedFundingPlan}
              className="form-check-input"
              disabled={disableProposed}
              onChange={this.handleParentChange('proposedFundingPlan')}
              type="checkbox"
            />
            Proposed Funding Plan
          </label>

          <div className="form-check">
            <label className="form-check-label">
              <input
                checked={!disableProposed && proposedFundingPlanSummaryData}
                className="form-check-input"
                disabled={disableProposed}
                onChange={this.handleChange(
                  'proposedFundingPlan',
                  'SummaryData',
                )}
                type="checkbox"
              />
              Summary Data
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <input
                checked={!disableProposed && proposedFundingPlanEquityMeasures}
                className="form-check-input"
                disabled={disableProposed}
                onChange={this.handleChange(
                  'proposedFundingPlan',
                  'EquityMeasures',
                )}
                type="checkbox"
              />
              Equity Measures
            </label>
          </div>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={controlValues}
              onChange={this.handleChange('Control Values')}
            />
            Control Values
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={districtData}
              onChange={this.handleChange('districtData')}
            />
            District Data
          </label>
        </div>
      </Modal>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      mapData: state.mapData,
    }),
    {
      generateCSV,
    },
  ),
)(ExportModal);
