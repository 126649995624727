import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './NavBar.scss';

class NavBar extends Component {
  render() {
    const {
      briefLink,
      onExportClick,
      mapData,
      simulators,
      stateName,
    } = this.props;
    if (!mapData) return null;
    return (
      <div className="container-fluid nav-bar sub-nav">
        <div
          className="btn-group state-selector"
          role="group"
          aria-label="State Selector"
        >
          {simulators.map((simulator) => (
            <NavLink
              key={simulator.id}
              to={'/states/' + simulator.name.toLowerCase()}
              activeClassName="active"
              className="btn btn-sm btn-outline-light"
            >
              {simulator.name}
            </NavLink>
          ))}
          <a href="/#about" className="btn btn-sm btn-link ml-2 about-project">
            About this project
          </a>
        </div>
        <div className="export-options">
          {briefLink && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light mr-2"
              href={briefLink}
            >
              Read {stateName} brief
            </a>
          )}
          <button className="btn btn-sm btn-primary" onClick={onExportClick}>
            Export
          </button>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      simulators: state.simulators,
      mapData: state.mapData,
    }),
    {},
  ),
)(NavBar);
