import React, { Component } from 'react';
import { omitBy, map, find } from 'lodash';

import { differenceObject, displayValue } from 'lib/number';

import LocalFunding from './LocalFunding';
import DistrictSelect from './DistrictSelect';
import SummaryBarItem from './SummaryBarItem';
import TooltipIcon from './TooltipIcon';

import './SummaryBar.scss';

const ALLOWED_VALUES = [
  'per_students_of_color',
  'percent_pov',
  'percent_ell',
  'percent_speced',
];

export default class SummaryBar extends Component {
  render() {
    const { mapData } = this.props;

    if (!mapData) return null;

    let key;
    // Prioritize highlighted data over selected
    if (mapData.highlightedDistrict) {
      key = 'highlightedDistrict';
    } else if (mapData.selectedDistrict) {
      key = 'selectedDistrict';
    }
    const districtData = pickFeatureProperties(mapData, key);
    const { state } = mapData;
    // Display highlighted > selected > state data
    const data = districtData ? districtData : state;
    const items = generateItems(data);

    const difObj = differenceObject(
      data.avg_funding_stu,
      data.proposed_avg_funding_stu,
    );

    return (
      <div className="row">
        <div className="col">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <DistrictSelect />
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12 col-md mt-3 mt-md-0">
                  <small>
                    Enrollment
                    <TooltipIcon
                      id="enrollment"
                      text="NCES 2015–16 Common Core of Data"
                    />
                  </small>
                  <h4 className="font-weight-normal">
                    {data.member ? data.member.toLocaleString() : 'No data'}
                  </h4>
                </div>
                <div className="col-12 col-md">
                  <small>
                    Grades served
                    <TooltipIcon
                      id="gradesServed"
                      text="NCES 2015–16 Common Core of Data"
                    />
                  </small>
                  <h4 className="font-weight-normal">
                    {gradeRange(data.gslo, data.gshi)}
                  </h4>
                </div>
                <div className="col-12 col-md">
                  <small>
                    Geography
                    <TooltipIcon
                      id="geography"
                      text="NCES 2015–16 Common Core of Data"
                    />
                  </small>
                  <h4 className="font-weight-normal">{data.geography}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <h3>Demographics</h3>
              <div className="row">{items}</div>
            </div>
            <div className="col-12 col-md-3 mb-3 mb-md-0">
              <h3 className="mb-0">Funding per student</h3>
              <small>Cost-adjusted dollars</small>
              <div className="row">
                <div className="col-12">
                  <small className="text-uppercase">Current</small>
                  <h4 className="font-weight-normal">
                    {displayValue(data.avg_funding_stu, 'avg_funding_stu')}
                  </h4>
                </div>
              </div>
              {state.proposed_avg_funding_stu && (
                <div className="row">
                  <div className="col-12 mb-3 mb-md-0">
                    <small className="text-uppercase">
                      Proposed
                      <span
                        className={`ml-1 ${
                          difObj.sign === '-' ? 'decrease' : 'increase'
                        }`}
                      >
                        {difObj.string}
                      </span>
                    </small>
                    <h4 className="font-weight-normal">
                      {displayValue(
                        data.proposed_avg_funding_stu,
                        'proposed_avg_funding_stu',
                      )}
                    </h4>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-3 mb-3 mb-md-0">
              <LocalFunding
                currentState={data.pct_funding_from_state}
                currentLocal={data.pct_funding_from_local}
                proposedState={data.proposed_pct_funding_from_state}
                proposedLocal={data.proposed_pct_funding_from_local}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const generateItems = (data) => {
  const allowedValues = omitBy(data, (v, k) => !ALLOWED_VALUES.includes(k));
  return map(allowedValues, (v, k) => (
    <SummaryBarItem value={v} key={k} labelKey={k} />
  ));
};

// Pass in data and key to get back the properties
const pickFeatureProperties = (mapData, key) => {
  if (!key) return null;
  const feature = find(
    mapData.geoJson.features,
    (feature) => feature.properties.unsdlea === mapData[key],
  );
  if (!feature) return null;
  return feature.properties;
};

const gradeRange = (gslo, gshi) => {
  // If we get bad data and those are empty just return an empty string
  if (!gslo && !gshi) return 'No data';
  let low = gslo,
    high = gshi;
  if (gslo === 'PK') {
    low = 'Pre K';
  }
  return low + '–' + high;
};
