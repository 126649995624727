import { compose } from 'recompose';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import React, { Component, Fragment } from 'react';
import { find, startCase } from 'lodash';

import Controls from './State/Controls';
import EquityMeasure from './State/EquityMeasure';
import ExportModal from './State/ExportModal';
import Loader from 'components/Loader';
import Map from './State/Map';
import MapLegend from './State/MapLegend';
import MobileBar from 'components/MobileBar';
import NavBar from './State/NavBar';
import SummaryBar from './State/SummaryBar';
import SummaryData from './State/SummaryData';
import TooltipIcon from './State/TooltipIcon';

import './State.scss';

import browserHistory from '../browserHistory';
import { resetControlValues, setControlValue } from 'modules/actions/controls';
import { getSimulator, getSimulators } from 'modules/actions/simulators';
import { highlightDistrict, selectDistrict } from 'modules/actions/mapData';
import { decodeValue } from 'lib/browser';

export class State extends Component {
  state = {
    exportModalOpen: false,
    controlsExpanded: false,
  };

  openExportModal = () => {
    this.setState({ exportModalOpen: true });
  };
  closeExportModal = () => {
    this.setState({ exportModalOpen: false });
  };
  toggleControls = () => {
    this.setState({ controlsExpanded: !this.state.controlsExpanded });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { getSimulator, getSimulators, location, match } = this.props;
    const { state } = match.params;
    await getSimulators();
    await getSimulator(state);

    if (location.search.length !== 0) {
      this.handleLocationSearch(location);
    }

    this.unlisten = browserHistory.listen(this.handleLocationSearch);
  };

  componentWillReceiveProps(nextProps) {
    const { getSimulator, match } = this.props;
    if (nextProps.match.params.state !== match.params.state) {
      getSimulator(nextProps.match.params.state);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleLocationSearch = (location, action) => {
    const allowedKeys = Object.keys(this.props.controls);
    const params = parse(location.search);

    Object.entries(params).forEach(([key, stringValue], index, array) => {
      if (!allowedKeys.includes(key)) {
        return;
      }
      this.props.setControlValue(
        {
          key,
          value: decodeValue(stringValue),
        },
        array.length === index + 1,
      ); // This causes us to make a request for proposed values
    });
  };

  render() {
    const {
      controls,
      highlightDistrict,
      mapData,
      resetControlValues,
      selectDistrict,
      setControlValue,
      simulators,
    } = this.props;
    const { exportModalOpen, controlsExpanded } = this.state;
    const stateName = mapData ? startCase(mapData.stateKey) : 'No State';
    const state = mapData ? mapData.state : {};
    // TODO: Move this to an action
    const { simulatorId } = state;
    const activeSimulator = find(simulators, { simulatorId });
    const briefLink = activeSimulator ? activeSimulator.brief : null;
    const maxValue = generateMaxValue(state);
    return (
      <Fragment>
        <ExportModal
          isOpen={exportModalOpen}
          onRequestClose={this.closeExportModal}
        />

        <NavBar
          briefLink={briefLink}
          stateName={stateName}
          onExportClick={this.openExportModal}
        />

        <div className="container-fluid">
          <div className="row position-relative">
            <Loader />
            <div className="col-md-8 display-content">
              <div className="row">
                <div className="col position-relative" style={{ padding: 0 }}>
                  <Map
                    mapData={mapData}
                    highlightDistrict={highlightDistrict}
                    selectDistrict={selectDistrict}
                  />
                  {mapData &&
                    mapData.stops && <MapLegend stops={mapData.stops} />}
                </div>
              </div>
              <div className="map-card card mb-3">
                <div className="card-body">
                  <SummaryBar mapData={mapData} />
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <h1 className="state-title">{stateName}</h1>
                  <div className="row my-2">
                    <SummaryData
                      title="Total funding"
                      subTitle="Actual dollars"
                      currentValue={state.funding}
                      proposedValue={
                        state.proposed_funding ? state.proposed_funding : null
                      }
                      toolTip="Total funding from state and local sources."
                      dataKey="funding"
                    />
                    <SummaryData
                      title="Funding per student"
                      subTitle="Cost-adjusted dollars"
                      currentValue={state.avg_funding_stu}
                      proposedValue={
                        state.proposed_avg_funding_stu
                          ? state.proposed_avg_funding_stu
                          : null
                      }
                      toolTip="Average across state, adjusted using the NCES Comparable Wage Index."
                      dataKey="avg_funding_stu"
                    />
                    <SummaryData
                      title="State share of funding"
                      currentValue={state.pct_funding_from_state}
                      proposedValue={
                        state.proposed_pct_funding_from_state
                          ? state.proposed_pct_funding_from_state
                          : null
                      }
                      toolTip="Calculated as a share of funding, in actual dollars."
                      dataKey="pct_funding_from_state"
                    />
                    <SummaryData
                      title="Local share of funding"
                      currentValue={state.pct_funding_from_local}
                      proposedValue={
                        state.proposed_pct_funding_from_local
                          ? state.proposed_pct_funding_from_local
                          : null
                      }
                      toolTip="Calculated as a share of funding, in actual dollars."
                      dataKey="pct_funding_from_local"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-5 mb-4 flex-wrap flex-md-nowrap">
                    <div>
                      <h2 className="h1 m-0">
                        Funding Equity Measures
                        <TooltipIcon
                          id="fundingEquityMeasures"
                          text="Calculated using the NCES Comparable Wage Index."
                        />
                      </h2>
                      <small>Cost-adjusted dollars</small>
                    </div>
                    <div className="legend">
                      <div className="legend-key">
                        Current
                        <div className="legend-key-current" />
                      </div>
                      <div className="legend-key">
                        Proposed
                        <div className="legend-key-proposed" />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <EquityMeasure
                      maxValue={maxValue}
                      title="Average funding per student by child poverty status"
                      groups={[
                        {
                          title: 'Students in poverty ',
                          current: Math.round(state.avg_funding_stu_inpov),
                          proposed: Math.round(
                            state.proposed_avg_funding_stu_inpov,
                          ),
                        },
                        {
                          title: 'Students not in poverty ',
                          current: Math.round(state.avg_funding_stu_notinpov),
                          proposed: Math.round(
                            state.proposed_avg_funding_stu_notinpov,
                          ),
                        },
                      ]}
                      toolTip="Poverty status for children ages 5 to 17 living in the school district, 2015 Census Bureau estimate."
                      text="These numbers compare funding levels for the district where the average student in poverty lives, relative to the district where the average student not in poverty lives."
                    />
                    <EquityMeasure
                      maxValue={maxValue}
                      title="Average funding per student by student geography"
                      groups={[
                        {
                          title: 'Urban students ',
                          current: Math.round(state.avg_funding_urb_stu),
                          proposed: Math.round(
                            state.proposed_avg_funding_urb_stu,
                          ),
                        },
                        {
                          title: 'Rural students ',
                          current: Math.round(state.avg_funding_rur_stu),
                          proposed: Math.round(
                            state.proposed_avg_funding_rur_stu,
                          ),
                        },
                      ]}
                      toolTip="Rural students are enrolled in “rural” or “town” schools; urban students are enrolled in “suburban” or “urban” schools."
                      text="These numbers show funding levels for the district for the average student enrolled in a rural school, relative to district funding for the average student enrolled in an urban school."
                    />
                    <EquityMeasure
                      maxValue={maxValue}
                      title="Average funding per student by race and ethnicity"
                      groups={[
                        {
                          title: 'Students of color ',
                          current: Math.round(state.avg_funding_col_stu),
                          proposed: Math.round(
                            state.proposed_avg_funding_col_stu,
                          ),
                        },
                        {
                          title: 'White students ',
                          current: Math.round(state.avg_funding_wh_stu),
                          proposed: Math.round(
                            state.proposed_avg_funding_wh_stu,
                          ),
                        },
                      ]}
                      toolTip="White students are classified as white, non-Hispanic students; students of color are nonwhite students."
                      text="These numbers show funding levels for the district for the average white student, relative to the district funding for the average student of color."
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 controls pt-4"
              style={controlsExpanded ? { transform: 'initial' } : {}}
            >
              <div className="d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap">
                <h1 className="m-0 mb-2 mb-lg-0 controls-label">
                  Funding Formula Controls
                </h1>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => resetControlValues()}
                >
                  Reset all to current
                </button>
              </div>

              <hr />

              <Controls setControlValue={setControlValue} controls={controls} />
            </div>
          </div>
        </div>
        <MobileBar
          briefLink={briefLink}
          onExportClick={this.openExportModal}
          onControlClick={this.toggleControls}
        />
      </Fragment>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      controls: state.controls,
      mapData: state.mapData,
      simulators: state.simulators,
    }),
    {
      getSimulator,
      getSimulators,
      highlightDistrict,
      resetControlValues,
      selectDistrict,
      setControlValue,
    },
  ),
)(State);

const potentialKeys = [
  'avg_funding_stu_inpov',
  'proposed_avg_funding_stu_inpov',
  'avg_funding_stu_notinpov',
  'proposed_avg_funding_stu_notinpov',
  'avg_funding_urb_stu',
  'proposed_avg_funding_urb_stu',
  'avg_funding_rur_stu',
  'proposed_avg_funding_rur_stu',
  'avg_funding_col_stu',
  'proposed_avg_funding_col_stu',
  'avg_funding_wh_stu',
  'proposed_avg_funding_wh_stu',
];
const generateMaxValue = (state) => {
  const values = [0];
  potentialKeys.forEach((key) => {
    if (state[key]) {
      values.push(Math.round(state[key]));
    }
  });
  return Math.max(...values);
};
