import { createAction } from 'redux-actions';

export const SET_MAP = 'SET_MAP';

export const setMap = createAction(SET_MAP);

export const HIGHLIGHT_DISTRICT = 'HIGHLIGHT_DISTRICT';
export const SELECT_DISTRICT = 'SELECT_DISTRICT';

export const highlightDistrict = createAction(HIGHLIGHT_DISTRICT);

export const selectDistrict = createAction(SELECT_DISTRICT);
