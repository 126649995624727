import React, { Component } from 'react';
import { round } from 'lodash';

import TooltipIcon from './TooltipIcon';
import './DistrictFunding.scss';

export default class LocalFunding extends Component {
  render() {
    const {
      currentState,
      currentLocal,
      proposedState,
      proposedLocal,
    } = this.props;

    const currentStateInt = round(currentState * 100);
    const currentLocalInt = currentLocal === 0 ? 0 : 100 - currentStateInt;

    // If we have a proposed, calculate the values
    let proposedStateInt, proposedLocalInt;
    if (proposedState !== undefined) {
      proposedStateInt = round(proposedState * 100);
      proposedLocalInt = proposedLocal === 0 ? 0 : 100 - proposedStateInt;
    }

    let lineStyle = {
      borderColor: '#1380c5',
      transform: `translateX(${proposedState * 100}%)`,
    };

    if (currentState > proposedState) {
      lineStyle = {
        borderColor: '#bb4401',
        transform: `translateX(${proposedState * 100}%)`,
      };
    }

    if (proposedState === 0 && proposedLocal === 0) {
      lineStyle.display = 'none';
    }

    return (
      <div className="row">
        <div className="col-12">
          <h3 id="state-and-local-share">
            State and local share
            <TooltipIcon
              id="state-and-local-share"
              text="Calculated as a share of actual spending."
            />
          </h3>
          <div className="row">
            <div className="col-12">
              <small className="text-uppercase">Current</small>
              <div className="district-bar">
                <small>State {currentStateInt}%</small>
                <div className="district-bar-graph">
                  <div
                    className="district-bar-graph-line"
                    style={{ transform: `translateX(${currentState * 100}%)` }}
                  />
                </div>
                <small>Local {currentLocalInt}%</small>
              </div>
            </div>
            {proposedState !== undefined && (
              <div className="col-12 ">
                <small className="text-uppercase">Proposed</small>
                <div className="proposed district-bar">
                  <small>State {proposedStateInt}%</small>
                  <div className="district-bar-graph">
                    <div
                      className="district-bar-graph-line"
                      style={lineStyle}
                    />
                  </div>
                  <small>Local {proposedLocalInt}%</small>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
