import 'App.scss';

import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import Home from 'pages/Home';
import State from 'pages/State';
import UrbanBar from 'components/UrbanBar';

import browserHistory from 'browserHistory';
import store from 'store';

const App = () => (
  <Router history={browserHistory}>
    <Provider store={store}>
      <div className="App">
        <UrbanBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/states/:state" component={State} />
        </Switch>
      </div>
    </Provider>
  </Router>
);

export default App;
