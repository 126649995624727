import { compact, sortedUniq } from 'lodash';

export const BLUE_COLOR_RAMP = [
  '#cfe8f3',
  '#a2d4ec',
  '#73bfe2',
  '#46abdb',
  '#1696d2',
];

const times = (n) => Array.from(Array(n)).map((_, i) => i);

export const quantileIntervals = (values, steps = 5) => {
  // Have to remove undefined values or this breaks when a bucket gets set to undefined
  const compactedValues = compact(values);
  const perBucket = Math.floor(compactedValues.length / steps);
  const sortedValues = [...compactedValues].sort((a, b) => a - b);
  const intervals = times(steps).reduce(
    (all, i) => [...all, sortedValues[perBucket + i * perBucket - 1]],
    [],
  );

  intervals[intervals.length - 1] = sortedValues[sortedValues.length - 1];

  return sortedUniq(intervals);
};
