import { createAction } from 'redux-actions';
import { find, startCase } from 'lodash';

import { setLoading } from './loading';
import { setControls } from './controls';
import { setMap } from './mapData';
import { getUrlFromHost } from '../../lib/browser';

const NODE_ENV = process.env.NODE_ENV;
const API_URL = getUrlFromHost();

export const GET_SIMULATORS = 'GET_SIMULATORS';
export const SET_SIMULATORS = 'SET_SIMULATORS';

const _getSimulators = createAction(GET_SIMULATORS);
const _setSimulators = createAction(SET_SIMULATORS);

// By default we only display the ones ready to be displayed
export const getSimulators = () => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(_getSimulators());
  // Hide ones we don't want to display in production, show them in development
  const paramString = NODE_ENV !== 'production' ? '' : '?display=1';
  const response = await fetch(API_URL + 'simulators/' + paramString);
  const payload = await response.json();
  dispatch(_setSimulators(payload));
};

export const GET_SIMULATOR = 'GET_SIMULATOR';

const _getSimulator = createAction(GET_SIMULATOR);

export const getSimulator = (state) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(_getSimulator);

  const { simulators } = getState();
  const simulator = find(simulators, { name: startCase(state) });
  if (!simulator) {
    return;
  }
  const simulatorId = simulator.id;

  // TODO: add some error handling here
  const response = await fetch(
    API_URL + 'form-data/?simulatorId=' + simulatorId,
  );
  const payload = await response.json();

  const simPayload = payload.simulators[0];
  let featureCollection;
  if (simPayload.geoJson) {
    // Get geoJSON separately with our geoJson field
    const geoJsonResponse = await fetch(API_URL + simPayload.geoJson);
    const jsonArray = await geoJsonResponse.json();
    featureCollection = jsonArray[0];
  }

  featureCollection.features.forEach((f) => {
    f.properties.unsdlea = f.properties.UNSDLEA;
    delete f.properties.UNSDLEA;
    return f;
  });

  const mapData = {
    center: simPayload.center.split(','), // payload.maps[0].center.split(","),
    zoom: [simPayload.zoom], // [payload.maps[0].zoom],
    // Converting so we can be consistent
    geoJson: featureCollection,
    districts: payload.districts,
    state: payload.states[0],
    stateKey: state,
    simulatorId,
  };

  await dispatch(
    setMap({
      mapData,
    }),
  );

  await dispatch(
    setControls({
      controls: payload.controls,
    }),
  );

  dispatch(setLoading(false));
};
