import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebFont from 'webfontloader';

import 'react-toggle/style.css';
import './index.scss';

WebFont.load({
  google: {
    families: ['Lato:300,400,700', 'sans-serif'],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
