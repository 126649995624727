import React, { Component } from 'react';

import { BLUE_COLOR_RAMP } from 'lib/map';
import { displayValue } from 'lib/number';

import './MapLegend.scss';

export default class MapLegend extends Component {
  render() {
    const { stops } = this.props;
    return (
      <div className="map-legend">
        <h1 className="h4">Funding per Student</h1>
        <small>Cost-adjusted dollars</small>
        <ol>
          {stops.map((value, index) => (
            <li key={index}>
              <span style={{ backgroundColor: BLUE_COLOR_RAMP[index] }} />
              <b>{'< ' + displayValue(value, 'dollar')}</b>
            </li>
          ))}
        </ol>
      </div>
    );
  }
}
