import { createAction } from 'redux-actions';
import { debounce } from 'lodash';

import { setLoading } from './loading';
import {
  getParamsFromControls,
  getUrlFromHost,
  setQueryString,
} from 'lib/browser';

const API_URL = getUrlFromHost();

export const RESET_CONTROL_VALUES = 'RESET_CONTROL_VALUES';
export const SET_CONTROL_VALUE = 'SET_CONTROL_VALUE';
export const SET_CONTROLS = 'SET_CONTROLS';

export const setControls = createAction(SET_CONTROLS);

const _setControlValue = createAction(SET_CONTROL_VALUE);
export const setControlValue = (payload, updateLocation = false) => (
  dispatch,
  getState,
) => {
  dispatch(_setControlValue(payload));
  if (updateLocation) {
    const state = getState();
    const params = getParamsFromControls(state.controls);
    setQueryString(params);

    const paramsWithId = Object.assign(params, {
      simulatorId: state.mapData.simulatorId,
    });
    dispatch(postProposed(paramsWithId));
  }
};

const _resetControlValues = createAction(RESET_CONTROL_VALUES);
export const resetControlValues = () => (dispatch, getState) => {
  dispatch(_resetControlValues());
  setQueryString(getParamsFromControls(getState().controls));
};

export const POST_PROPOSED = 'POST_PROPOSED';
export const UPDATE_PROPOSED = 'UPDATE_PROPOSED';

const _updateProposed = createAction(UPDATE_PROPOSED);

const _postProposed = createAction(POST_PROPOSED);

// This is the function lodash debounce is calling
const actualPostProposed = async (params, dispatch) => {
  dispatch(setLoading(true));
  await dispatch(_postProposed);
  const body = JSON.stringify([params]);
  console.log(`POST to ${API_URL}run-simulation/`, body);
  // TODO: add some error handling here
  const response = await fetch(API_URL + 'run-simulation/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const payload = await response.json();

  await dispatch(_updateProposed(payload.results));

  dispatch(setLoading(false));
};

const debouncedPostProposed = debounce(actualPostProposed, 300);

export const postProposed = (params) => (dispatch) => {
  // We are debouncing this so we don't slam the server
  return debouncedPostProposed(params, dispatch);
};
