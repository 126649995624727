import { stringify, parse } from 'qs';
import { isEqual, isNaN, toNumber } from 'lodash';

import browserHistory from 'browserHistory';

export const getParamsFromControls = (controls) => {
  return Object.values(controls).reduce((all, control) => {
    // Do not add params if there is no proposed value
    if (control.proposedValue === null) {
      return all;
    }
    return {
      ...all,
      [control.key]: control.proposedValue.toString(),
    };
  }, {});
};

export const setQueryString = (params) => {
  // don't push the same exact url params onto the history
  // removing the ? at the start of the string
  if (isEqual(params, parse(window.location.search.slice(1)))) return;

  const url = `${window.location.pathname}?${stringify(params)}`;
  browserHistory.push(url);
};

export const decodeValue = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  const number = toNumber(value);
  return isNaN(number) ? value : number;
};

// Check to see if the app is running from production host.
// We are doing this because the default deploy on codeship
// doesn't allow multiple env vars for different deployments
export const getUrlFromHost = () => {
  const PRODUCTION_HOST = process.env.REACT_APP_PRODUCTION_HOST;
  const APP_HOST = window.location.host;
  return APP_HOST === PRODUCTION_HOST
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
};
