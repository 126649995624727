import { isInteger, isNumber, round } from 'lodash';

export function clamp(value, min, max) {
  // Checkboxes should just return the value
  if (value === true || value === false) {
    return value;
  }
  // Convert a string value to a number value for comparison
  const num = value * 1;
  // If we are clamping as part of the URL being set we will be missing min and max
  // so pass back the number
  if (min === undefined && max === undefined) {
    return num;
  }
  // Check to make sure the number is within the min or max
  return num <= min ? min : num >= max ? max : num;
}

export function normalize(value, min, max) {
  return (value - min) / (max - min);
}

export function floatToPercentString(value, maxDecimals = 0) {
  let decimals = 0;
  if (Math.floor(value) !== value) {
    decimals = value.toString().split('.')[1].length || 0;
  }
  if (decimals > maxDecimals) decimals = maxDecimals;
  return round(value * 100, decimals) + '%';
}

// Values should be an integer if possible
export function differenceObject(
  origVal = 0,
  newVal = 0,
  alreadyPercentage = false,
) {
  const obj = {};
  if (origVal > newVal) {
    obj.change = origVal - newVal;
    obj.sign = '-';
  }
  if (origVal < newVal) {
    obj.change = newVal - origVal;
    obj.sign = '+';
  }
  if (origVal === newVal) {
    obj.change = 0;
    obj.sign = '=';
  }
  const change = (obj.change / origVal) * 100;
  let decimals = 0;
  if (Math.abs(change) < 9) {
    decimals = 1;
  }
  obj.percent = round(change, decimals)
    .toString()
    .toLocaleString();
  obj.string = obj.sign + obj.percent + '%';
  return obj;
}

export function displayValue(value, key) {
  if (value === undefined || value === null) {
    return 0;
  }
  if (
    (isInteger(value) && value !== 1 && value !== 0) ||
    (key && !key.match('pct'))
  ) {
    const int = Math.round(value);
    return '$' + int.toLocaleString();
  }
  if (isNumber(value)) {
    return floatToPercentString(value);
  }
  return 0;
}
