import React, { Component } from 'react';

import Control from './Control';
import ConnectedControl from './ConnectedControl';

export default class Controls extends Component {
  render() {
    const { controls, setControlValue } = this.props;
    const groupedControls = groupControls(controls);
    return Object.values(groupedControls).map((control) => {
      if (control.groupKey) {
        return (
          <ConnectedControl
            key={control.groupKey}
            controls={control.controls}
            groupKey={control.groupKey}
            setControlValue={setControlValue}
          />
        );
      }
      return (
        <Control
          {...control}
          key={control.key}
          field={control.key}
          onChange={(ev) => {
            const value =
              control.toggle !== null && control.toggle !== undefined
                ? ev.target.checked
                : ev.target.value;
            setControlValue(
              {
                key: control.key,
                value,
                rangeLower: control.rangeLower,
                rangeUpper: control.rangeUpper,
              },
              true,
            );
          }}
        />
      );
    });
  }
}

const groupControls = (controls) => {
  const groupedControls = {};
  Object.keys(controls).forEach((cKey) => {
    if (controls[cKey].groupKey) {
      const { groupKey } = controls[cKey];
      groupedControls[groupKey] = groupedControls[groupKey] || {};
      groupedControls[groupKey].controls =
        groupedControls[groupKey].controls !== undefined
          ? groupedControls[groupKey].controls
          : [];
      groupedControls[groupKey].groupKey = 'weights';
      groupedControls[groupKey].controls.push(controls[cKey]);
    } else {
      groupedControls[cKey] = controls[cKey];
    }
  });
  return groupedControls;
};
