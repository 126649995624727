import React from 'react';
import Toggle from 'react-toggle';
import classnames from 'classnames';

import TooltipIcon from './TooltipIcon';

import { floatToPercentString, normalize } from 'lib/number';

import lock from '../../lock.svg';
import unlock from '../../unlock.svg';

import './Control.scss';

export const ToggleControl = ({
  label,
  field,
  toggle,
  defaultValue,
  proposedValue,
  unit,
  helpText,
  tooltipText,
  onChange,
}) => (
  <div className="row mb-5 mb-md-4">
    <div className="col">
      <h3>
        {label}
        <TooltipIcon id={field} text={tooltipText} />
      </h3>
      <form>
        <div className="form-row">
          <div className="col-12 col-lg-7">
            <label htmlFor="formControlRange">
              <p>{helpText}</p>
              <small className="form-text text-muted">
                Current: {toggle === true ? 'On' : 'Off'}
              </small>
            </label>
          </div>
          <div className="col-12 col-lg-5">
            <div className="d-flex justify-content-end">
              <Toggle
                checked={proposedValue !== null ? proposedValue : toggle}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
);

export const SliderControl = ({
  label,
  field,
  defaultValue,
  proposedValue,
  rangeLower,
  rangeUpper,
  stepValue,
  unit,
  helpText,
  tooltipText,
  onLock,
  step,
  locked,
  onChange,
}) => {
  const defaultValueMarkerStyle = {
    left: `calc(${normalize(defaultValue, rangeLower, rangeUpper) * 100}%)`,
  };
  const sliderClasses = classnames('form-control', 'mb-0');
  return (
    <div className="row mb-4 control-slider">
      <div className="col">
        <h3>
          {label}
          <TooltipIcon id={field} text={tooltipText} />
        </h3>
        <form>
          <div className="form-row">
            <div className="col-12 col-lg-7">
              <label htmlFor="formControlRange" className="form-control-range">
                <p>{helpText}</p>
                <div className="form-row align-items-baseline">
                  <div className="form-group mb-0 col-12">
                    <label className="sr-only" htmlFor="control-input">
                      {label}
                    </label>
                    <div className="input-group">
                      <input
                        name={field}
                        type="number"
                        min={rangeLower}
                        max={rangeUpper}
                        step={step ? step : 0.1}
                        className={sliderClasses}
                        value={
                          proposedValue !== null ? proposedValue : defaultValue
                        }
                        disabled={locked}
                        onChange={onChange}
                      />
                      {onLock && (
                        <div className="input-group-append">
                          <div className="lock-control-wrapper input-group-text p-0">
                            <div className="lock-control">
                              <input
                                type="checkbox"
                                checked={locked}
                                onChange={onLock}
                                name={`lock_${field}`}
                                id={`lock_${field}`}
                                className="sr-only"
                              />
                              <label htmlFor={`lock_${field}`}>
                                <span className="lock-group lock-unchecked">
                                  <img
                                    className="lock-control-icon"
                                    src={lock}
                                    alt="lock"
                                  />
                                  <span className="sr-only">Lock Control</span>
                                </span>
                                <span className="lock-group lock-checked">
                                  <img
                                    className="lock-control-icon"
                                    src={unlock}
                                    alt="unlock"
                                  />
                                  <span className="sr-only">
                                    Unlock control
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <small className="form-text text-muted col-12">
                    Current:{' '}
                    {unit === 'Percentage'
                      ? floatToPercentString(defaultValue, 10)
                      : defaultValue.toString().toLocaleString() + ' ' + unit}
                  </small>
                </div>
              </label>
            </div>
            <div className="col-12 col-lg-5">
              <div className="range">
                {false && (
                  <div
                    className="default-value-marker"
                    style={defaultValueMarkerStyle}
                  />
                )}
                <input
                  type="range"
                  className="form-control-range"
                  id="formControlRange"
                  name={field}
                  min={rangeLower}
                  max={rangeUpper}
                  value={proposedValue !== null ? proposedValue : defaultValue}
                  step={step ? step : 0.1}
                  disabled={locked}
                  onChange={onChange}
                />
                <div className="range-labels">
                  <span className="range-lower-bound">
                    {unit === 'Percentage'
                      ? floatToPercentString(rangeLower, 10)
                      : rangeLower.toLocaleString()}
                  </span>
                  <span className="range-upper-bound">
                    {unit === 'Percentage'
                      ? floatToPercentString(rangeUpper, 10)
                      : rangeUpper.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const Control = (props) => {
  if (props.toggle === true || props.toggle === false)
    return <ToggleControl {...props} />;

  return <SliderControl {...props} />;
};

export default Control;
