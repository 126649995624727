import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { getSimulators } from 'modules/actions/simulators';

import { Massachusetts, Nebraska, Texas, Virginia } from 'components/StateSVG';

import './Home.scss';

class Home extends Component {
  components = {
    Massachusetts,
    Nebraska,
    Texas,
    Virginia,
  };

  componentDidMount() {
    this.props.getSimulators();
  }

  render() {
    const { simulators } = this.props;
    return (
      <div className="home">
        <div id="hero-image" />
        <div className="col-lg-12 col-xl-11 mx-auto py-4 px-4 home-content">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <h1>Directing Dollars to School Districts</h1>
              <p className="subheader">
                Computing the Effects of Changes to State Funding Formulas
              </p>
              <p className="date">October 31, 2018</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <p>
                Most dollars for schools are directed through state funding
                formulas. These formulas attempt to allocate state and local
                dollars to school districts in an equitable way. To do so,
                funding formulas must account for differences across districts,
                including residents' wealth, enrolled students, and geographic
                location.
              </p>
              <p>
                Formula parameters that adjust for district differences are
                important, but these adjustments may interact in ways that make
                it hard to understand overall effects on funding. To remedy
                this, we have built interactive models of funding formulas for
                Massachusetts, Nebraska, Texas, and Virginia. These models allow
                you to directly adjust parameters in each state’s funding
                formula and see how your changes affect funding in your district
                and across the state.
              </p>
            </div>
            <div className="col-lg-6 mr-auto">
              <div className="row">
                {simulators.map((simulator) => {
                  const Component = this.components[simulator.name];
                  return (
                    <div className="col-12 col-md-6 mb-4" key={simulator.id}>
                      <Link
                        to={'/states/' + simulator.name.toLowerCase()}
                        className="state-link"
                      >
                        <Component />
                        <span>{simulator.name}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div id="about" className="col-12 col-md-6">
              <h2>About this project</h2>
              <p>
                This feature was funded by the Laura and John Arnold Foundation.
                We are grateful to them and to all our funders, who make it
                possible for Urban to advance its mission. The views expressed
                are those of the authors and should not be attributed to the
                Urban Institute, its trustees, or its funders. Funders do not
                determine our research findings or the insights and
                recommendations of our experts. More information on our funding
                principles is available&nbsp;
                <a href="https://www.urban.org/aboutus/our-funding/funding-principles">
                  here
                </a>
                . Read our terms of service{' '}
                <a href="https://www.urban.org/terms-service">here</a>.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <h2>Project Credits</h2>
              <div className="row">
                <div className="col-12 col-md-6">
                  <h3>Research</h3>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/kristin-blagg"
                      >
                        Kristin Blagg
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/matthew-chingos"
                      >
                        Matthew Chingos
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/victoria-lee"
                      >
                        Victoria Lee
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/cary-lou"
                      >
                        Cary Lou
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/stipica-mudrazija"
                      >
                        Stipica Mudrazija
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/victoria-rosenboom"
                      >
                        Victoria Rosenboom
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <h3>Development</h3>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/david-s-dorio"
                      >
                        David D’orio
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/jessica-kelly"
                      >
                        Jessica Kelly
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/jeffrey-levy"
                      >
                        Jeffrey Levy
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/sybil-mendonca"
                      >
                        Sybil Mendonca
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/silke-taylor"
                      >
                        Silke Taylor
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="http://mobility-labs.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Rick Metzger
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="http://mobility-labs.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Sean Perkins
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <h3>Editorial and Communications</h3>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/benjamin-chartoff"
                      >
                        Ben Chartoff
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/david-hinson"
                      >
                        David Hinson
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.urban.org/author/alexandra-tilsley"
                      >
                        Alex Tilsley
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      simulators: state.simulators,
    }),
    {
      getSimulators,
    },
  ),
)(Home);
