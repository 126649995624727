import React from 'react';

import './Modal.scss';

export const Modal = ({
  isOpen,
  onRequestClose,
  title,
  actionLabel,
  onActionClick,
  actionDisabled,
  children,
}) => {
  return (
    <div
      className={`modal fade ${isOpen ? 'show' : ''}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onRequestClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">{children}</div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              disabled={actionDisabled}
              onClick={onActionClick}
            >
              {actionLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
